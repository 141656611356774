import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.authenticate');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-authenticate',
template: templateSource || '',
    styles: [`

/* file: /var/www/kmbig-survey/project/main/build/src/app/page.authenticate/view.scss */
.wrapper {
  background-image: url(/assets/images/bubble.png);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.wrapper-box {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.circle {
  background-color: rgba(255, 255, 255, 0.4);
  border: 3px solid #000;
  width: 140px;
  height: 140px;
  top: -90px;
  left: calc(50% - 70px);
  border-radius: 70%;
  box-shadow: 0px 6px 9px rgba(0, 0, 0, 0.1607843137);
  z-index: 5;
  margin: 0 auto;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}
.circle .logo {
  width: 100%;
  max-width: 80px;
  margin: auto;
}
.circle .logo img {
  width: 100%;
}

.form {
  padding: 20px 0;
  z-index: 2;
  color: #000000;
  background-color: white;
  border-radius: 16px;
  position: relative;
  padding-bottom: 52px;
}
.form input {
  border-bottom-color: #F2F2F3;
  border: 1px solid #000;
}
.form input:disabled {
  border-right-color: #000;
  border-left-color: #000;
  color: var(--wc-gray);
  text-align: center;
}
.form input:first-child {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-top-color: #000;
  font-family: "main-r";
}
.form input:last-child {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-color: #000;
  font-family: "main-r";
}

.wrapper .form-success .container h1 {
  transform: translateY(85px);
}

.container {
  max-width: 600px;
  margin: 0 auto;
  padding: 15vh 0;
  min-height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
}
@media (max-width: 768px) {
  .container {
    padding: 10vh 16px;
  }
}

.box-login {
  border-radius: 10px;
  background-color: #fff;
  box-sizing: border-box;
  width: 70%;
  margin: auto;
  margin-top: 60px;
}
@media (max-width: 768px) {
  .box-login {
    width: 80%;
  }
}

.form input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: 0;
  border-width: 1px;
  border-bottom: 1px solid #F2F2F3;
  background-color: rgb(255, 255, 255);
  display: block;
  font-size: 18px;
  color: #2b2929;
  transition-duration: 0.25s;
  position: relative;
  width: 100%;
  margin: 0;
  padding: 18px 19px 19px;
  box-sizing: border-box;
}

.form input:hover {
  background-color: rgb(255, 255, 255);
}

.form input:focus {
  background-color: rgb(255, 255, 255);
  width: 100%;
  color: #000000;
}

.form button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: 0;
  border: 1px solid rgb(0, 0, 0);
  background-color: rgb(0, 0, 0);
  border-radius: 10px;
  display: block;
  text-align: center;
  font-size: 18px;
  color: #ffffff;
  transition-duration: 0.25s;
  position: relative;
  width: 70%;
  margin: auto;
  padding: 18px 19px 19px;
  box-sizing: border-box;
  font-family: "main-r";
}
@media (max-width: 768px) {
  .form button {
    width: 80%;
  }
}

.addition-info {
  width: 70%;
  margin: auto;
  font-size: 14px;
  display: block;
  margin-top: 24px;
}
@media (max-width: 768px) {
  .addition-info {
    width: 80%;
  }
}
.addition-info a {
  color: var(--wc-text);
  cursor: pointer;
}
.addition-info a:hover {
  color: #6380de;
}

.form button:hover {
  color: var(--wc-blue);
  border: 1px solid var(--wc-blue);
  background-color: rgba(108, 141, 246, 0.2);
}

.hint {
  text-align: center;
  border-radius: 10px;
  padding: 16px;
  color: var(--wc-text);
  background-color: var(--wc-light);
}

.addition-info {
  margin-top: 24px;
  font-size: 14px;
  display: flex;
}
.addition-info a {
  color: var(--wc-text);
}
.addition-info a:hover {
  color: #6380de;
}`],
})
export class PageAuthenticateComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    public returnTo: string = "notset";

    public async ngOnInit() {
        await this.service.init();
        let returnTo = this.getParam('returnTo', '/ocr/list');
        let check = await this.service.auth.allow(false, returnTo);
        if (check)
            this.returnTo = returnTo;
    }

    public getParam(sname, defaultvalue) {
        let params = location.search.substring(location.search.indexOf("?") + 1);
        let sval = defaultvalue;
        params = params.split("&");
        for (let i = 0; i < params.length; i++) {
            let temp = params[i].split("=");
            if ([temp[0]] == sname) { sval = temp[1]; }
        }
        return decodeURIComponent(sval);
    }

    public view: string = 'login.id';

    public data: any = {
        email: '',
        password: ''
    };

    public async alert(message: string, status: string = 'error', cancel: any = false, action: string = '확인') {
        return await this.service.alert.show({
            title: "",
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }

    public async login() {
        let user = JSON.parse(JSON.stringify(this.data));
        if (user.password)
            user.password = this.service.auth.hash(user.password);
        else
            delete user.password;

        let { code, data } = await wiz.call("login", user);

        if (code == 200) {
            location.href = this.returnTo;
        } else if (code == 201) {
            this.view = "login.password";
            await this.service.render();
            try {
                setTimeout(() => {
                    document.querySelector("input[type=password]").focus();
                }, 150);
            } catch (err) {
                console.log(err);
            }
        } else if (code == 301) {
            let isjoin = await this.alert(data, 'success', '닫기', '계정 만들기');
            if (!isjoin) return;
            this.view = "join.terms";
            await this.service.render();
        } else {
            await this.alert(data, 'error');
        }
    }
}

export default PageAuthenticateComponent;