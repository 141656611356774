import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.user.list');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-user-list',
template: templateSource || '',
    styles: [`

/* file: /var/www/kmbig-survey/project/main/build/src/app/page.user.list/view.scss */
.btlr {
  border-top-left-radius: 10px;
}

.btrr {
  border-top-right-radius: 10px;
}

.br-10 {
  border-radius: 10px;
}

.box-shadow {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
}

.form-select {
  border: 1px solid #C9C5F0;
  border-radius: 20px;
  text-align: center;
}

.btn-request {
  background-color: #E2E4F0;
  color: var(--wiz-color-navy);
  border-radius: 10px;
  border: 1px solid #E2E4F0;
  font-weight: bold;
  pointer-events: none;
}

.btn-allow {
  background-color: #64CF7C;
  color: white;
  border-radius: 10px;
  border: 1px solid #64CF7C;
  font-weight: bold;
  pointer-events: none;
}

.btn-reject {
  background-color: #F0142F;
  color: white;
  border-radius: 10px;
  border: 1px solid #F0142F;
  font-weight: bold;
  pointer-events: none;
}

.btn-process {
  background-color: #C9C5F0;
  color: black;
  border-radius: 10px;
  border: 1px solid #C9C5F0;
  font-weight: bold;
  pointer-events: none;
}

.board-table {
  background: white;
}
.board-table td,
.board-table th {
  white-space: nowrap;
  text-align: left;
}
.board-table .width-1 {
  width: 80px;
}
.board-table .width-2 {
  width: 120px;
}
.board-table .width-3 {
  width: 180px;
}
.board-table .width-4 {
  width: 240px;
}
.board-table .width-5 {
  width: 300px;
}
.board-table thead th {
  background-color: #B9B9DD;
  color: black;
  font-size: 16px;
  font-weight: bold;
  padding: 12px 0;
}

input[type=checkbox] {
  border-radius: 50% !important;
}
input[type=checkbox]:checked {
  background-color: #B9B9DD;
  border-color: #B9B9DD;
}

.btn-edit {
  border-radius: 10px;
  border: 1px solid #B9B9DD;
  font-size: 15px;
  font-weight: bold;
  color: black;
}
.btn-edit:hover {
  color: #4F41F1;
  border: 1px solid #4F41F1;
}`],
})
export class PageUserListComponent implements OnInit {
    constructor(@Inject( Service    )         public service: Service    ) { }

    public page = {
        start: 1,
        end: 1,
        current: 1,
    };

    public async ngOnInit() {
        await this.service.init();
        await this.pageLoad(1);
        await this.service.render();
    }

    public async load() {
        let { code, data } = await wiz.call("load", { mode: this.mode, page: this.page.current });
        if (code != 200) return;
        this.list = data.users;
        for (let i = 0; i < this.list.length; i++) {
            if (this.list[i].is_expert == 0) {
                this.list[i].is_expert = false;
            } else {
                this.list[i].is_expert = true;
            }
        }
        this.lastpage = data.lastpage;
        this.page.start = (parseInt((this.page.current - 1) / 10) * 10) + 1;
        this.page.end = this.lastpage;
        await this.service.render();
    }

    public async load() {
        let { code, data } = await wiz.call("load");
        if (code == 200) {
            this.list = data;
        }
        await this.service.render();
    }

    public async changeRole(item) {
        let user = item;
        let { code } = await wiz.call("change", user)
        if (code != 200) return;
        this.service.toast.success("변경되었습니다.")
    }

    private pageLoad(p: number) {
        this.page.current = p;
        this.load();
    }

}

export default PageUserListComponent;