import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.ocr.list');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-ocr-list',
template: templateSource || '',
    styles: [`

/* file: /var/www/kmbig-survey/project/main/build/src/app/page.ocr.list/view.scss */
.btlr {
  border-top-left-radius: 10px;
}

.btrr {
  border-top-right-radius: 10px;
}

.br-10 {
  border-radius: 10px;
}

.box-shadow {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
}

.board-table {
  background: white;
}
.board-table td,
.board-table th {
  white-space: nowrap;
  text-align: left;
}
.board-table .width-1 {
  width: 80px;
}
.board-table .width-2 {
  width: 120px;
}
.board-table .width-3 {
  width: 180px;
}
.board-table .width-4 {
  width: 240px;
}
.board-table .width-5 {
  width: 300px;
}
.board-table thead th {
  background-color: #B9B9DD;
  color: black;
  font-size: 16px;
  font-weight: bold;
  padding: 12px 0;
}
.board-table tr {
  border-bottom: 1px solid #d4d4f0;
}

.btn-edit {
  border-radius: 10px;
  border: 1px solid #B9B9DD;
  color: black;
}
.btn-edit:hover {
  color: #4F41F1;
  border: 1px solid #4F41F1;
}`],
})
export class PageOcrListComponent implements OnInit {
    constructor(@Inject( Service    )         public service: Service    ) { }

    public async ngOnInit() {
        await this.service.init();
        let session = this.service.auth.session;
        if (Object.keys(session).length === 0) {
            this.service.href(`/`);
            return;
        }
        this.userRole = session.role;
        this.userEmail = session.email;

        await this.load();
        await this.service.render();
    }

    public async load() {
        let { code, data } = await wiz.call("load");
        if (code == 200) {
            this.list = data;
        }
    }

    public async edit(id) {
        location.href = `/ocr/edit/${id}`;
    }
}

export default PageOcrListComponent;