import '@angular/compiler';
import { PageUserListComponent } from './page.user.list/page.user.list.component';
import { PortalSeasonViewerTreeComponent } from './portal.season.viewer.tree/portal.season.viewer.tree.component';
import { PageOcrEditComponent } from './page.ocr.edit/page.ocr.edit.component';
import { LayoutEmptyComponent } from './layout.empty/layout.empty.component';
import { PageAuthenticateComponent } from './page.authenticate/page.authenticate.component';
import { PagePageMypageComponent } from './page.page.mypage/page.page.mypage.component';
import { PagePageJoinComponent } from './page.page.join/page.page.join.component';
import { PortalSeasonPagenationComponent } from './portal.season.pagenation/portal.season.pagenation.component';
import { PageOcrListComponent } from './page.ocr.list/page.ocr.list.component';
import { PageOcrAnalysisComponent } from './page.ocr.analysis/page.ocr.analysis.component';
import { ComponentNavigationComponent } from './component.navigation/component.navigation.component';
import { PortalSeasonLoadingComponent } from './portal.season.loading/portal.season.loading.component';
import { PortalSeasonUiDropdownComponent } from './portal.season.ui.dropdown/portal.season.ui.dropdown.component';
import { LayoutNavbarComponent } from './layout.navbar/layout.navbar.component';
import { PortalSeasonStatusbarComponent } from './portal.season.statusbar/portal.season.statusbar.component';
import { PortalSeasonTabComponent } from './portal.season.tab/portal.season.tab.component';
import { PortalSeasonLoadingHexaComponent } from './portal.season.loading.hexa/portal.season.loading.hexa.component';
import { PortalSeasonLoadingSeasonComponent } from './portal.season.loading.season/portal.season.loading.season.component';
import { PortalSeasonAlertComponent } from './portal.season.alert/portal.season.alert.component';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, COMPOSITION_BUFFER_MODE } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SortablejsModule } from "src/libs/portal/season/ngx-sortablejs";
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';

@NgModule({
    declarations: [
        AppComponent,
        PageUserListComponent,
        PortalSeasonViewerTreeComponent,
        PageOcrEditComponent,
        LayoutEmptyComponent,
        PageAuthenticateComponent,
        PagePageMypageComponent,
        PagePageJoinComponent,
        PortalSeasonPagenationComponent,
        PageOcrListComponent,
        PageOcrAnalysisComponent,
        ComponentNavigationComponent,
        PortalSeasonLoadingComponent,
        PortalSeasonUiDropdownComponent,
        LayoutNavbarComponent,
        PortalSeasonStatusbarComponent,
        PortalSeasonTabComponent,
        PortalSeasonLoadingHexaComponent,
        PortalSeasonLoadingSeasonComponent,
        PortalSeasonAlertComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        NgbModule,
        SortablejsModule,
        KeyboardShortcutsModule.forRoot(),
        
    ],
    providers: [{
        provide: COMPOSITION_BUFFER_MODE,
        useValue: false  
    }],
    bootstrap: [AppComponent]
})
export class AppModule { }